/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react/no-danger */
import React, { FC, useState } from 'react';
import Collapsible from 'react-collapsible';
import { TFunction, useTranslation } from 'react-i18next';
import { Header, Icon } from 'semantic-ui-react';

import { bem } from '../../../../core/design/bem';
import { Workshop } from '../../../../types/workshop.types';
import { EntityTags } from '../../../EntityTags';
import SpeakerAvatars from '../../../speakers/SpeakerAvatars';

type AccordionDetailsProps = {
  workshop: Workshop;
  showSpeakers: boolean;
  speakersConfig: Record<string, any>;
} & Record<string, any>;

const css = bem('AccordionDetails');
const translationPrefix = 'workshops';
const AccordionDetails: FC<AccordionDetailsProps> = (props: AccordionDetailsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { tagField = 'category' } = props;
  const { workshop, showSpeakers, speakersConfig = {}, tagFields = [tagField] } = props;
  const { size, imageSize, ...rest } = speakersConfig;
  const {
    startDate,
    endDate,
    title,
    description,
    shortDescription,
    speakers,
    location,
    subCategory,
  } = workshop;

  const detailDescription = description || shortDescription;
  const showDescription =
    !!detailDescription || location || subCategory || (showSpeakers && speakers?.length > 0);
  const dateLabel = getDateLabel(startDate, endDate, t);

  return (
    <>
      {!!dateLabel && <div className={css('header')}>{dateLabel}</div>}
      <Collapsible
        open={isOpen}
        trigger={
          <div className={css('title')}>
            {showDescription && <Icon name={!isOpen ? 'chevron right' : 'chevron down'} />}
            <Header as="h3" className="title">
              {title}
            </Header>
            <EntityTags tagFields={tagFields} entity={workshop} type="workshop" />
          </div>
        }
        transitionTime={200}
        onOpening={() => setIsOpen(true)}
        onClosing={() => setIsOpen(false)}
      >
        {showDescription && (
          <div className={css('details')}>
            {subCategory && (
              <div className="subCategory">
                <Icon name="tag" /> {subCategory}
              </div>
            )}
            {location && (
              <div className="location">
                <Icon name="map marker alternate" /> {location}
              </div>
            )}
            {description && (
              <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
            )}
            {!description && shortDescription && (
              <div className="description">{shortDescription}</div>
            )}
            {showSpeakers && (
              <SpeakerAvatars
                variant="details"
                speakerIds={speakers}
                size={size}
                imageSize={imageSize}
                {...rest}
              />
            )}
          </div>
        )}
      </Collapsible>
    </>
  );
};

const getDateLabel = (startDate: any, endDate: any, t: TFunction) => {
  if (!startDate || (!startDate && !endDate)) {
    return undefined;
  }

  if (!endDate) {
    return t(`${translationPrefix}.start-time`, { startDate });
  }

  return t(`${translationPrefix}.time`, { startDate, endDate });
};

export { AccordionDetails };
