/* eslint-disable @typescript-eslint/no-explicit-any */
import clone from 'lodash/clone';
import setWith from 'lodash/setWith';

export function setIn(
  object: Record<string, any>,
  path: string | string[],
  value: unknown,
): Record<string, any> {
  return setWith(clone(object), path, value, (v) => {
    // Note : _.setWith fails if value is null
    if (v === null) return undefined;
    return clone(v);
  });
}

export function applyPatch(data: Record<string, any>, patch: unknown): Record<string, any> {
  if (typeof patch === 'function') {
    return patch(data);
  }
  if (patch && typeof patch === 'object') {
    let obj = data;
    // Handle patches like {"foo.bar": "baz"}
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const [key, value] of Object.entries(patch)) {
      obj = setIn(obj, key, value);
    }
    return obj;
  }
  // eslint-disable-next-line no-console
  console.error('Unknown patch type', patch);
  return data;
}
