import { useMount } from 'ahooks';
import { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import { TrackingContext } from './Context';
import Layout from './Layouts/Layout';
import Styles from './Styles';
import ForgottenPasswordScreen from './authentication/ForgottenPasswordScreen';
import LoginScreen from './authentication/LoginScreen';
import ResetPasswordScreen, { AutoLoginScreen } from './authentication/ResetPasswordScreen';
import BackToTopArrow from './components/BackToTopArrow';
import BlockingPopup from './components/BlockingPopup';
import CookieBanner from './components/CookieBanner/CookieBanner';
import CookieContextProvider from './components/CookieBanner/CookieContextProvider';
import { ExtraHtml } from './components/ExtraHtml/ExtraHtml';
import FeedbackChecker from './components/FeedbackChecker';
import { GamificationProvider } from './components/GamificationProvider';
import GlobalModalManager from './components/GlobalModalManager/GlobalModalManager';
import InstallPWA from './components/InstallPWA';
import ScrollToTop from './components/ScrollToTop';
import WelcomePopup from './components/WelcomePopup';
import { useConfig, useUpdateConfig } from './config/config.context';
import { useLoginPath } from './config/screens.context';
import platformService from './core/services/platform.service';
import { trackEvent, trackPage } from './core/trackers';
import CheckMustResetPassword from './profile/components/CheckMustResetPassword';
import { useIsAnonymous } from './profile/hooks';
import store from './shared/Store';
import { lang } from './utils';
import GlobalConfigSync from './utils/GlobalConfigSync';
import { computeSearchParam } from './utils/urlUtils';
import { FirebaseProvider } from './utils/useFirebase';
import { SessionCheck } from './utils/useSessionCheck';

function ensureFreshConfig(Component) {
  return (props) => {
    const { menu, debug } = useConfig();
    const updateConfig = useUpdateConfig();
    useMount(async () => {
      if (debug?.disableConfigSync) return;
      updateConfig(await platformService.fetchConfig());
    });
    if (!menu) return null; // Menu isn't loaded yet
    return <Component {...props} />;
  };
}

function useScrollToSectionById() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }, [location.pathname, location.hash]);
}

const ConnectedApp = ensureFreshConfig(() => {
  const { isPlatformClosed } = useConfig();
  if (isPlatformClosed) {
    store.disconnect();
  }
  const isAnonymous = useIsAnonymous();

  return (
    <FirebaseProvider>
      <Layout />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop
        enableMultiContainer
        closeOnClick
        pauseOnHover={false}
        progressClassName="toastify-progress-bar"
      />
      <GlobalConfigSync />
      <BackToTopArrow />
      <WelcomePopup />
      <ExtraHtml />
      <BlockingPopup />
      {!isAnonymous && (
        <>
          <SessionCheck />
          <CheckMustResetPassword />
          <FeedbackChecker />
          <GamificationProvider />
        </>
      )}
    </FirebaseProvider>
  );
});

const PrivateRoute = ({ component: Component, render, loginPath, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        // eslint-disable-next-line no-nested-ternary
        !store.mustRedirectToLogin() ? (
          Component ? (
            <Component {...props} />
          ) : (
            render(props)
          )
        ) : (
          <Redirect
            to={{
              pathname: loginPath,
              // Basepath is not taken in account because we already add it on logout
              // Moreover, when we try to get some url, basepath is already part of location.pathname
              search: computeSearchParam(props.location?.pathname),
            }}
          />
        )
      }
    />
  );
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { screens, eventId } = useConfig();
  const loginPath = useLoginPath();

  window.appHistory = useHistory(); // Used for react native history push
  const [queryClient] = useState(() => new QueryClient());
  useEffect(() => {
    if (screens) {
      trackPage(location.pathname, screens);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, !!screens]);

  useScrollToSectionById();

  useEffect(() => {
    store.checkConnection().then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return null;
  }
  return (
    <CookieContextProvider eventId={eventId} lang={lang()}>
      <QueryClientProvider client={queryClient}>
        <TrackingContext.Provider value={{ trackEvent }}>
          <Styles />
          <ScrollToTop />
          <Provider store={store.reduxStore}>
            <Switch>
              <Route exact path={loginPath} component={LoginScreen} />
              <Route exact path="/resetPassword" component={ResetPasswordScreen} />
              <Route exact path="/forgottenPassword" component={ForgottenPasswordScreen} />
              <Route exact path="/auth/autoLogin" component={AutoLoginScreen} />
              <PrivateRoute path="/" component={ConnectedApp} loginPath={loginPath} />
            </Switch>
            <InstallPWA />
            <GlobalModalManager />
            <CookieBanner lang={lang()} eventId={eventId} />
          </Provider>
        </TrackingContext.Provider>
      </QueryClientProvider>
    </CookieContextProvider>
  );
};

export default App;
