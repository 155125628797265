/* eslint-disable react/no-danger */
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Card, Header, Icon, Modal } from 'semantic-ui-react';

import { userAgenda } from '../../../agenda/store/agenda.selectors';
// eslint-disable-next-line import/no-cycle
import UserRegistrationModal from '../../../authentication/components/UserRegistrationModal/UserRegistrationModal';
import ClickmeetingAccessButton from '../../../components/ClickmeetingAccessButton';
import QuotaField from '../../../components/workshops/QuotaField';
import WorkshopDate, { WorkshopTimeRemaining } from '../../../components/workshops/WorkshopDate';
import { useConfig } from '../../../config/config.context';
import { useScreenConfig } from '../../../config/screens.context';
import { bem } from '../../../core/design/bem';
import { useIsAnonymous } from '../../../profile/hooks';
import { workshopProptypes } from '../../../propTypes';
import { AddWorkshopToCalendar } from '../../../workshops/blocks/WorkshopAddToCalendarBlock';
import {
  useWorkshopSessionRegistration,
  useWorkshopSessions,
} from '../../store/workshopSessions.hooks';
import './WorkshopSessionModal.scss';
import FilterMenu from './components/FilterMenu';

const translationPrefix = 'workshop-sessions.sessions-modal';

const cxModal = bem('workshop-session-modal');

const WorkshopSession = ({ session, onClick, selectedSession }) => {
  const { t } = useTranslation();
  const { usersCount, quota, _id, location } = session;
  const isSelected = selectedSession?._id === _id;
  const remainingPlaces = Math.max(0, quota - usersCount);
  const isFull = remainingPlaces === 0;
  return (
    <Card
      fluid
      as={Button}
      className={cxModal('card').state({ active: isSelected }).toString()}
      disabled={isFull}
      onClick={() => onClick(session)}
    >
      <Card.Header>{t('common.session-time', session)}</Card.Header>
      {!!location && (
        <Card.Description className="location" style={{ marginTop: 4 }}>
          <Icon name="map marker alternate" />
          {location}
        </Card.Description>
      )}
      <QuotaField quota={quota} usersCount={usersCount} />
    </Card>
  );
};

WorkshopSession.defaultProps = {
  selectedSession: undefined,
};

WorkshopSession.propTypes = {
  onClick: PropTypes.func.isRequired,
  selectedSession: PropTypes.object,
  session: PropTypes.object.isRequired,
};

export function useGroupByDay(items, key = 'startDate') {
  return groupBy(items, (item) => moment(item[key], moment.ISO_8601).format('YYYY-MM-DD'));
}

function isFilterHidden(filterConfig, sessions) {
  const { visible, field: filterField } = filterConfig;

  return !visible || !filterField || !sessions || sessions.length === 0;
}

function useFilterElementOptions(config, sessions) {
  const { languages = [] } = useConfig();
  return useMemo(() => {
    const { field: filterField, options: filterOptions = [] } = config;
    if (isFilterHidden(config, sessions)) return { isHidden: true };
    const sessionsByFilter = groupBy(sessions, filterField);
    const options =
      filterField === 'language' || filterField === 'lang' ? languages : filterOptions;

    return {
      isHidden: false,
      options: options.map((item) => ({
        ...item,
        count: sessionsByFilter[item.value]?.length || 0,
      })),
      sessionsByFilter,
    };
  }, [config, languages, sessions]);
}

function useFilter(workshop, sessions) {
  const { lang } = useConfig();
  const filterConfig = get(
    useScreenConfig(workshop.collection || 'workshops'),
    ['sessionsModal', 'filter'],
    {},
  );

  const { isHidden, options, sessionsByFilter } = useFilterElementOptions(filterConfig, sessions);

  const { field: filterField } = filterConfig;
  const defaultFilter =
    filterField === 'language' || filterField === 'lang' ? lang : filterConfig.options?.[0]?.value;

  const [filter, setFilter] = useState(defaultFilter);

  // No filter
  if (isHidden) {
    return { filterElement: null, filteredSessions: sessions };
  }

  // Filter !
  return {
    filterElement: (
      <FilterMenu value={filter} options={options} onChange={setFilter} pointing secondary />
    ),
    filteredSessions: sessionsByFilter[filter] ?? [],
  };
}

const WorkshopSessionsModal = ({ workshop, showDescription, onClose, config }) => {
  const { t } = useTranslation();
  const { collection, title } = workshop;
  const workshopId = workshop?.workshopId || workshop._id;
  const { data: sessions = [] } = useWorkshopSessions(collection, workshopId);
  const { constraints, allowOverlappingSessions = {} } = config;

  const agenda = useSelector(userAgenda);
  const registration = agenda.find(
    (evt) => evt._id === workshopId || evt.workshopId === workshopId,
  );

  const isAnonymous = useIsAnonymous();

  const [selectedSession, setSelectedSession] = useState();
  const {
    loading,
    registerWorkshopSession,
    unregisterWorkshopSession,
  } = useWorkshopSessionRegistration(
    { ...workshop, ...(selectedSession || registration) },
    { allowOverlappingSessions, constraints },
    registration,
  );
  const handleConfirm = async () => {
    const hasRegistered = await registerWorkshopSession();
    if (hasRegistered) {
      onClose();
    }
  };
  const handleUnregister = async () => {
    const hasUnregistered = await unregisterWorkshopSession();
    if (hasUnregistered) {
      setSelectedSession(undefined);
    }
  };

  const { filterElement, filteredSessions } = useFilter(workshop, sessions);

  const sessionsByDay = useGroupByDay(filteredSessions);
  const days = Object.keys(sessionsByDay);

  if (isAnonymous) {
    return (
      <UserRegistrationModal
        onClose={() => {
          window.location.reload();
        }}
      />
    );
  }

  if (registration) {
    // Already registered
    const isToday = moment(registration.startDate).isSame(new Date(), 'day');
    return (
      <Modal open onClose={onClose} className={cxModal().toString()}>
        <Modal.Header>{t(`${translationPrefix}.header`, { title })}</Modal.Header>
        <Modal.Content>
          {showDescription && workshop.description && (
            <div
              style={{ marginBottom: 20 }}
              dangerouslySetInnerHTML={{ __html: workshop.description }}
            />
          )}
          <Header as="h4">{t(`workshops.workshop.registered`)}</Header>
          <div>
            <div style={{ display: 'inline-block', marginRight: 20, marginBottom: 8 }}>
              <WorkshopDate startDate={registration.startDate} />
              <div>
                <AddWorkshopToCalendar primary workshop={registration} fluid={false} />
              </div>
            </div>
            {/* {isToday && ( */}
            <div style={{ display: 'inline-block' }}>
              <WorkshopTimeRemaining date={registration.startDate} />
              <div>
                <ClickmeetingAccessButton event={registration} disabled={!isToday} />
              </div>
            </div>
            {/* )} */}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>{t('btn.cancel')}</Button>

          <Button primary onClick={handleUnregister} loading={loading} disabled={loading}>
            {t(`workshops.workshop.unregister`)}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  return (
    <Modal open onClose={onClose} className={cxModal().toString()}>
      <Modal.Header>{t(`${translationPrefix}.header`, { title })}</Modal.Header>
      <Modal.Content>
        {showDescription && workshop.description && (
          <div
            style={{ marginBottom: 20 }}
            dangerouslySetInnerHTML={{ __html: workshop.description }}
          />
        )}
        {filterElement}
        <Header as="h4">
          {t(
            `${translationPrefix}.${
              filteredSessions.length > 0 ? 'select-session' : 'no-available-session'
            }`,
          )}
        </Header>
        {days.map((day) => {
          return (
            <React.Fragment key={day}>
              <Header as="h4" className="header--date">
                <Icon name="calendar outline alternate" />{' '}
                <Header.Content>
                  {t(`common.date`, {
                    date: moment(day, 'YYYY-MM-DD'),
                    context: 'workshop-session-modal',
                  })}
                </Header.Content>
              </Header>
              <Card.Group itemsPerRow={4}>
                {sessionsByDay[day].map((session) => (
                  <WorkshopSession
                    key={session._id}
                    session={session}
                    selectedSession={selectedSession}
                    onClick={setSelectedSession}
                  />
                ))}
              </Card.Group>
            </React.Fragment>
          );
        })}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>{t('btn.cancel')}</Button>

        <Button
          primary
          onClick={handleConfirm}
          loading={loading}
          disabled={loading || !selectedSession}
        >
          {t(`workshop-sessions.register`)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

WorkshopSessionsModal.defaultProps = {
  config: {},
  showDescription: false,
};

WorkshopSessionsModal.propTypes = {
  config: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  showDescription: PropTypes.bool,
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};
export default WorkshopSessionsModal;
